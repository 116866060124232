<template>
  <div class="productClass">
    <!-- 背景首图 -->
    <div class="productClass_banner">
      <div class="productClass_bannerImg">
        <img src="@/assets/img/product/banner-bg-cplis.jpg" />
      </div>
      <div class="productClass_bannerContent">
        <div>炼就金睛火眼，成就智能机器</div>
        <div>Make golden eyes and make intelligent machines</div>
        <!-- <div class="productClass_bannerSearch">
          <input
            type="text"
            v-model="search"
            @blur="handleFocus(0)"
            @focus="handleFocus(1)"
            :style="`opacity: ${opacity}`"
          />
          <img src="@/assets/img/product/btn-serch.png" />
        </div> -->
      </div>
    </div>
    <!-- 列表内容 -->
    <div class="productClass_list">
      <!-- 四大模块 -->
      <!-- <div class="productClass_listSelect"> -->
      <!-- 视觉检测 -->
      <!-- <div @click="active = 0">
          <div class="productClass_listSelect_img">
            <div v-if="active != 0">
              <img src="@/assets/img/product/ic-sjjc.png" />
            </div>
            <div v-else>
              <img src="@/assets/img/product/ic-sjjc-cur.png" />
            </div>
          </div>
          <div
            class="productClass_listSelect_text"
            :style="active == 0 ? 'color: #000080;' : 'color: #9AA9D8;'"
          >
            视觉检测
          </div>
        </div> -->
      <!-- 视觉引导 -->
      <!-- <div @click="active = 1">
          <div class="productClass_listSelect_img">
            <div v-if="active != 1">
              <img src="@/assets/img/product/ic-sjyd.png" />
            </div>
            <div v-else>
              <img src="@/assets/img/product/ic-sjyd-cur.png" />
            </div>
          </div>
          <div
            class="productClass_listSelect_text"
            :style="active == 1 ? 'color: #000080;' : 'color: #9AA9D8;'"
          >
            视觉引导
          </div>
        </div> -->
      <!-- 3D 视觉 -->
      <!-- <div @click="active = 2">
          <div class="productClass_listSelect_img">
            <div v-if="active != 2">
              <img src="@/assets/img/product/ic-3d.png" />
            </div>
            <div v-else>
              <img src="@/assets/img/product/ic-3d-cur.png" />
            </div>
          </div>
          <div
            class="productClass_listSelect_text"
            :style="active == 2 ? 'color: #000080;' : 'color: #9AA9D8;'"
          >
            3D 视觉
          </div>
        </div> -->
      <!-- AI 应用 -->
      <!-- <div @click="active = 3">
          <div class="productClass_listSelect_img">
            <div v-if="active != 3">
              <img src="@/assets/img/product/ic-AI.png" />
            </div>
            <div v-else>
              <img src="@/assets/img/product/ic-AI-cur.png" />
            </div>
          </div>
          <div
            class="productClass_listSelect_text"
            :style="active == 3 ? 'color: #000080;' : 'color: #9AA9D8;'"
          >
            AI 应用
          </div>
        </div> -->
      <!-- 选中状态 -->
      <!-- <div
          class="productClass_listSelect_status"
          :style="`left: calc(25% * ${active};`"
        ></div> -->
      <!-- </div> -->
      <div class="productClass_listContent">
        <!-- 列表 -->
        <div class="productClass_listContent_list">
          <div v-for="(item, index) in casestudyList" :key="index" @click="goNewsDetails('/newsDetails', item)">
            <div>
              <img :src="item.imgUrl" />
            </div>
            <div>{{ item.title }}</div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="productClass_listContent_pagination">
          <el-pagination background layout="prev, pager, next" :total="1">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 输入框值
      search: "",
      // 输入框透明度
      opacity: 0,
      // 被选中
      active: 0,
      // 案例列表
      casestudyList: [
        {
          imgUrl: require("@/assets/img/news/2019112000.jpg"),
          title: "口罩视觉检测软件使用说明",
          time: "2019-11-20 09:00",
          content: `
        <div>
<div class="div-middle">
<div class="row">
    <div class="col-md-10">
        <div class="context" style="text-align:left; padding:10px;">
            <p style="font-weight:600; font-size:22px; text-align:left">
                口罩视觉检测软件
            </p>
            <p>
                口罩能够对于进入肺部的空气进行一定的过滤。在呼吸道传染病流行的时候，在粉尘等污染环境中作业的时候口罩的作重显得十分重要。市面上的口罩种类繁多， 运用场景不一。口罩的材质与质量也参差不齐，口罩品质检测成为工业生产的必要工序之一。
            </p>
             <p style="font-weight:600; font-size:22px; text-align:left">
                系统简介
            </p>					
            <p>                      
                    系统安装简单、在口罩生产运行皮带上安装摄像头与光源。
                    摄像头与电脑（PC或服务器）连接。在电脑安装微林口罩检测系统。
                    设置好必要参数就可以对经过皮带的口罩进行尺寸位置检测、整洁度检测、触点检测等
            </p>
            
            <p style="font-weight:600; font-size:24px; text-align:left">
                软件打开界面如下：
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019112001.jpg")}" style="width:60%" alt=" 口罩视觉检测" />
            </div>
            <p>    
               如果没有相机设置说明相机没连接上，需要检测相机并重启软件。
            </p>
           <p style="font-weight:600; font-size:22px; text-align:left">
                1. 开启检测：
            </p>
            <p>    
              按顺序点击连接，开始就会软件就开始检测，需要注意的是采集模式为触发采集，触发源为line0，当触发信号输入便触发检测一次。
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019112002.jpg")}" style="width:60%" alt=" 口罩视觉检测" />
            </div>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019112003.jpg")}" style="width:60%" alt=" 口罩视觉检测" />
            </div>
            <p style="font-weight:600; font-size:22px; text-align:left">
                2. 设置：
            </p>
             <p style="font-weight:600; font-size:22px; text-align:left">
               调整方框：：
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019112004.jpg")}" style="width:60%" alt=" 口罩视觉检测" />
            </div> 
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019112005.jpg")}" style="width:60%" alt=" 口罩视觉检测" />
            </div>

             <p>    XY宽高为调整图像中的检测区域；</p>
             <p>    S是相机的实际视野高度；</p>
             <p>    这些参数在相机安装是就会设定好，只有在相机位置调整时才修改。</p>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
</div>
</div>
      `,
          info: "口罩视觉检测软件口罩能够对于进入肺部的空气进行一定的过滤。在呼吸道传染病流行的时候，在粉尘等污染环境中作业的时候口罩的作重显得十分重要。市面上的口罩种类繁多， 运用场景不一。口罩的材质与质量也参差不齐，口罩品质检测成为工业生产的必要工序之一。系统简介系统安装简单、在口罩生产运行皮带上安装摄像头与光源。 摄像头与电脑（PC或服务器）连接。在电脑安装微林口罩检测系统。 设置好必要参数就可以对经过皮带的口罩进行尺寸位置检测、整洁度检测、触点检测等",
        },
        {
          imgUrl: require("@/assets/img/news/2019091200.png"),
          title: "小金属配件视觉检测",
          time: "2019-09-12 16:31",
          content: `
        <div>
<div class="div-middle">
<div class="row">
    <div class="col-md-10">
        <div class="context" style="text-align:left; padding:10px;">

            <p> 
经常有人问，我们究竟视觉检测，检测是什么东西？
</p>
            <p> 
我们会回答一般生产上的一切物品都有可能用到我们的视觉检测 
</p>
            <p> 
例如：
</p>
            <p> 
你说生产一包食物吧，要检查它它的生产日期！
</p>
            <p> 

生产一个零件吧，要检测他的角度啊，深度啊，还有他的各种标准。
</p>
            <p> 

</p>
            <p> 
那我们对这些所有产品的检测一般归为：视觉定位、视觉计数、有无、测量、识别、扫码

</p>
            <p> 
当然，还有更深层次的应用视觉引导马垛，流水线控制机械臂控制。

</p>
            <p> 
二维升级到三维就有双目3d、结构光3d、红外3D
</p>
            <p> 
再深层次的应用就是深度学习了

</p>
            <p> 
那这一切这一切的检测为了是什么？
</p>
            <p> 
为了控制智能机器去把这些不良品剔除。

</p>
            <p> 
今天为大家介绍一下这款小金属配件的视觉检测

</p>
            <p> 
首先是有无检测？没有正方形如下图.

            </p>
              <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091201.png")}" style="width:40%" />
            </div>
              <p>
             再者就是检测铜圈是否有压坏
              </p>
             
              <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091202.png")}" style="width:40%" />
            </div>
            
            
            <p>
                本次检测我们用到了海*的相机，参数为：

            </p>
            <ul class="lic">
                <li>
                    1、130 万像素 1/3”
                </li>
                <li>
                    2、帧率：30 fps
                </li>
                <li>
                    3、色彩：黑白
                </li>
                <li>
                    4、快门：卷帘
                </li>
                <li>
                    5、 CMOS 千兆以太网
                </li>
                <li>
                    6:增益:0 dB ~ 20 dB
                </li>
                <li>
                    7:焦距:8mm
                </li>
            </ul>

            <p>
                用到的光源：
            </p>
            <ul class="lic">
                <li>
                    1、同轴白色光源：亮度 满亮
                </li>


            </ul>

            <p>
                WD：116mm  W:27:mm   H:20mm
            </p>
            <p>
                曝光时间：33~82μs 
            </p>

          
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
</div>
</div>
      `,
          info: "经常有人问，我们究竟视觉检测，检测是什么东西？我们会回答一般生产上的一切物品都有可能用到我们的视觉检测例如：你说生产一包食物吧，要检查它它的生产日期！生产一个零件吧，要检测他的角度啊，深度啊，还有他的各种标准。那我们对这些所有产品的检测一般归为：视觉定位、视觉计数、有无、测量、识别、扫码当然，还有更深层次的应用视觉引导马垛，流水线控制机械臂控制。二维升级到三维就有双目3d、结构光3d、红外3D",
        },
        {
          imgUrl: require("@/assets/img/news/2019110500.jpg"),
          title: "条码标签快速视觉检测-污点检测",
          time: "2019-11-05 19:00",
          content: `
        <div class="div-title">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="titlePartLeftTitle" style="">条码标签快速视觉检测-污点检测-</div>
        <div class="titlePartLeftContent">
            <p style="text-indent:2em">
                随着社会的发展,商品的日益丰富、商品品质成为人们茶余饭后讨论的问题。物品的追溯成为关键，商品条形、二维码就成为能溯源的关键。所以条码、二维码质量尤为重要。
            </p>
        </div>
    </div>
    <div class="col-md-6">
        <div class="titlePartRightContent" style="">
            <img style="width:80%" src="${require("@/assets/img/news/Tiaomakuaishu01-450.png")}">
        </div>
    </div>
</div>
</div>
<div style="clear: both;"></div>
</div>
<div class="div-odd">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="">
            <div style="margin:auto; margin-top:50px">
                <ul class="firstLi">
                    <li>
                        系统简介
                        <ul class="secondLi">
                            <li>
                                您还为贵公司生产出来的条码、二维码不能读取、标签有污点、出货数量不精准而烦恼吗？我们的系统能为你省心！
                            </li>
                            <li>
                                本项目是条码生产最后一道工序，功能包括：检测条码、二维码是否能正常被设备读取、检测码区域外是否存在污点、各类条码计数统计导出等。
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="">
            <div class="div-img" style="text-align:center;  margin:10px auto 10px auto">
                <img  src="${require("@/assets/img/news/tiaomakuaishu02-450.png")}" style ="width:60%" />
            </div>
        </div>
    </div>
</div>
</div>
</div>

<div class="div-even">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="">
            <div style="margin:auto; margin-top:50px">
                <ul class="firstLi">
                    <li>
                        系统安装
                        <ul class="secondLi">
                            <li>
                                本项目安装在条码运转机边上，相机、镜头、光源从外向内。
                            </li>
                            <li>
                                本软件适合在window操作系统上运行。
                            </li>
                            <li>
                                电脑利用千兆网卡、网线与相机相连，实现高速、稳定、可靠通讯。

                            </li>
                            <li>
                                无刷电机带动条码带高速运转，可调速的无刷电机为了适应各类形的条码。
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="">
            <div class="div-img" style="text-align:center;  margin:auto">
                <img  class="img80" src="${require("@/assets/img/news/tiaomakuaishu03-450.png")}" />
            </div>

        </div>
    </div>
</div>
</div>
</div>

<div class="div-odd">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="">
            <div style="margin:auto; margin-top:50px">
                <ul class="firstLi">
                    <li style="color:black">
                        检测内容
                        <ul class="secondLi">
                            <li>
                                *检测条码是否正常被设备读取。
                            </li>
                            <li>
                                *转码停机：出现上一个码与下一个码不一致时停机。
                            </li>
                            <li>
                                *污点检测：条码区域外检测是否存在污点。
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-6">
         
            <div class="div-img" style="text-align:center;  margin:10px auto 10px auto">
                <img class="img80" src="${require("@/assets/img/news/tiaomakuaishu04-450.png")}" />
            </div>
       
    </div>
</div>
</div>
</div>

<div class="div-even">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="">
            <div style="margin:auto; margin-top:50px">
                <ul class="firstLi">
                    <li>
                        系统显示
                        <ul class="secondLi">
                            <li>
                                *软件左边是相机的各项参数设置。以配合各类型的条码。
                            </li>
                            <li>
                                *软件中间显示摄像头实时图像，并实时圈出检测范围与结果。
                            </li>
                            <li>
                                *软件右边以文字方式显示检测结果、条码数量统计、软件工作模式、图片操作区等
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="div-img" style="text-align:center;  margin:10px auto 10px auto">
            <img  src="${require("@/assets/img/news/tiaomakuaishu05-450.png")}" />
        </div>
    </div>
</div>
</div>
</div>
      `,
          info: "标签条码检测、污点检测、转码检测",
        },
        {
          imgUrl: require("@/assets/img/news/2019110400.jpg"),
          title: "大金属块-铁块正反检测-有无螺母检测",
          time: "2019-11-04 14:10",
          content: `
        
<div class="div-title">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="titlePartLeftTitle" style="">大金属块-铁块正反检测-有无螺母检测-</div>
        <div class="titlePartLeftContent">
            <p style="text-indent:2em">
               随着工业自动化的不断进步创新、金属大铁块上料也全自动化了。金属块有正反面，正面比较平整，反面有点弧度，正面要求在指定位置焊接螺母。
            </p>
        </div>
    </div>
    <div class="col-md-6">
        <div class="titlePartRightContent" style="">
            <img src="${require("@/assets/img/news/jinshukuai01-600.png")}">
        </div>
    </div>
</div>
</div>
<div style="clear: both;"></div>
</div>
<div class="div-odd">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="">
            <div style="margin:auto; margin-top:50px">
                <ul class="firstLi">
                    <li>
                        系统简介
                        <ul class="secondLi">
                            <li>
                                本项目加工流程共有三个工位，第一个工位功能是视觉判断金属块正反，第二个工位是在金属块正机焊接螺母，第三个工位是视觉判断螺母是否已经正确焊上。
                                用到视觉检测的是第一、三工位。如右图：左右两个工位就是第三与第一工位。 我们的相机、镜头与光源安装在工位上方。
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="">
            <div class="div-img" style="text-align:center;  margin:10px auto 10px auto">
                <img src="${require("@/assets/img/news/jinshukuai02.jpg")}" />
            </div>
        </div>
    </div>
</div>
</div>
</div>

<div class="div-even">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="">
            <div style="margin:auto; margin-top:50px">
                <ul class="firstLi">
                    <li>
                        检测过程
                        <ul class="secondLi">
                            <li>
                                上述已经说过，整个过程有三个工位。运作第一个工位前（即机械手抓取铁块前），工人们要将铁块放到该工位的卡槽里。此时工人们无须刻意将铁块正反面放置。
                            </li>
                            <li>
                                启动系统，机械手抓取铁块，将铁块放置到工位一的摄像机前。铁块正反检测启动，摄像机先拍照对图像进行分析处理。如果是正面，视觉系统给机械手正面信号。
                            </li>
                            <li>
                                收到正面信号后，机械手将铁块抓取到第二工位进行焊螺母处理。
                            </li>
                            <li>
                                螺母焊接完成后机械手将铁块抓取到第三工位，视觉系统再次启动，检测是否正确的焊好螺母。
                            </li>
                           
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="">
            <div class="div-img" style="text-align:center;  margin:10px auto 10px auto">
                <img src="${require("@/assets/img/news/jinshukuaijiq.jpg")}" />
            </div>
        </div>
    </div>
</div>
</div>
</div>

<div class="div-odd">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="">
            <div style="margin:auto; margin-top:50px">
                <ul class="firstLi">
                    <li style="color:black">
                        合格品展示
                        <ul class="secondLi">
                            <li>
                                *右图左边是铁块正反面检测，结果为正面，系统给出OK信号
                            </li>
                            <li>
                                *右图右边是铁块有无螺母检测，结果显示存在螺母，系统给出OK信号
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-6">
         
            <div class="div-img" style="text-align:center;  margin:10px auto 10px auto">
                <img class="img100" src="${require("@/assets/img/news/jinshukuai04-430.jpg")}" />
            </div>
       
    </div>
</div>
</div>
</div>

<div class="div-even">
<div class="div-middle">
<div class="row">
    <div class="col-md-6">
        <div class="">
            <div style="margin:auto; margin-top:50px">
                <ul class="firstLi">
                    <li>
                        不良品处理
                        <ul class="secondLi">
                            <li>
                                *第一工位检测到反面时，系统给信号机械手。请求机械手将铁块放到回收箱。等待工人们再次放入。
                            </li>
                            <li>
                                *第三工位检测螺母是否正确安装。如果符合标准，系统将金属块放置到塑料架上，等待取用。
                            </li>
                            <li>
                                *第三工位如果检测螺母不正确安装，则将金属块放到回收箱，等待工作人员再次确认！
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="div-img" style="text-align:center;  margin:10px auto 10px auto">
            <img class="img100" src="${require("@/assets/img/news/jinshukuai05-430.jpg")}" />
        </div>
    </div>
</div>
</div>
</div>
      `,
          info: "正反面检测-螺母检测系统正式上线",
        },
        {
          imgUrl: require("@/assets/img/news/20191127173123345.png"),
          title: "CCD检测软件对显像品质的优劣分析",
          time: "2019-11-27 17:20",
          info: "机器视觉检测系统软件中，显像品质的优劣对检测实际效果拥有挺大危害，清楚详细的显像图型才可以让技术工程师更强的对商品检测結果开展剖析，保证不闲测漏检。而工业生产镜头则系统对显像的品质拥有立即的危害功效，因而在挑选选购镜头时就必须十分谨慎，应遵照下列几个标准：",
          content: `
        <div>
<div class="div-middle">
<div class="row">
    <div class="col-md-10">
        <span style="font-size:16px;">&nbsp;&nbsp;&nbsp; 机器视觉检测系统软件中，显像品质的优劣对检测实际效果拥有挺大危害，清楚详细的显像图型才可以让技术工程师更强的对商品检测結果开展剖析，保证不闲测漏检。而工业生产镜头则系统对显像的品质拥有立即的危害功效，因而在挑选选购镜头时就必须十分谨慎，应遵照下列几个标准：</span><br />
<p>
<br />
</p>
<p>
<img src="${require("@/assets/img/news/20191126185121_1176.png")}" alt="" />
</p>
<p>
<br />
</p>
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;机器视觉工业生产镜头</span><br />
<br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;1、机器视觉镜头可适用的较大的CCD规格不可以低于所配搭的照相机中CCD控制器集成ic的规格。光源穿透镜头聚焦点在CCD集成ic上，是图象获得的关键因素，殊不知镜头的清楚显像是有一定范畴限定的，因而，只能镜头的较大适配<a href="/" target="_blank">CCD检测</a>规格超过或者相当于机器视觉系统软件中照相机的CCD规格，才可以让照相机获得详细的清楚图象。</span><br />
<br />
<img src="${require("@/assets/img/news/20191127171706_8452.jpg")}" alt="" /><br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;2、机器视觉镜头的光谱仪特点要合乎光源的规定。在机器视觉系统软件中，镜头不仅仅与照相机相互作用力的，镜头还需考虑光源规定，才可以获得所有的图象信息内容。这里必须考虑到光源的光波长、光谱仪范畴、及其光源的类型是红外线還是紫外线这些。</span><br />
<br />
<br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;3、机器视觉镜头的工作中间距要适度。说白了工作中间距，就是指当图象在焦距范围内的那时候，物块和镜头前端开发的间距。不适度的工作中间距，都是减少显像特性，提升检测成本费。根据镜头焦距、工作中间距、CCD规格这种人们可以获知镜头的视场范畴，挑选镜头的标准也另外包含了镜头视场遮盖的标准。</span><br />
<br />
<img src="${require("@/assets/img/news/20191121180039_4569.jpg")}" alt="" /><br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;4、机器视觉镜头插口要跟照相机插口配对安裝，也可根据变换配对安裝。现阶段，市面较为常见的有二种镜头与照相机接口标准，一种是C插口，另一种是CS插口。二者插口全是外螺纹口，较大的差别取决于从镜头到照相机表面的间距不一样，在其中C插口的间距是17.526mm，CS插口的间距是12.5mm。此外，C插口能够根据应用转化器替代CS插口开展应用，换句话说，C插口能够用以CS安裝座照相机上，而CS插口则不可以用以C安裝座照相机上。</span><br />
<br />
<br />
<span style="font-size:16px;">&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;在选购工业生产镜头时，会有很多型号规格主要参数供顾客挑选，在所难免令人一些头痛，但无论是哪样型号规格哪样主要参数的工业相机，在挑选的那时候遵照左右几个点标准去看看，就会轻轻松松许多。</span><br />
<br /> 
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
</div>
</div>
      `,
        },
        {
          imgUrl: require("@/assets/img/news/2019111400.jpg"),
          title: "汽车仪表盘面视觉定位切割",
          time: "2019-11-14 18:00",
          content: `
        <div>
<div class="div-middle">
<div class="row">
    <div class="col-md-10">
        <div class="context" style="text-align:left; padding:10px;">
            <p style="font-weight:600; font-size:22px; text-align:left">
                视觉定位切割
            </p>
            <p>
                 工业是立国之本、强国之基；信息化是最鲜明的时代特征之一，正在深刻重塑人类社会
            </p>
            <p>
               我国工业化建设取得了重大成就，尤其是汽车工业自动化。整车的制造与装配基本实现无人制造。
            </p>
            <p>
                今天为大家介绍汽车仪表盘的一个小孔切割。在利用机器视觉前，是人工切割。现在人工成本相当高，而且人会带着各种情绪上班。
                一不留神就会装口切大，切大了就不美观。切小了，配件无法进入这个孔。
            </p>

            <p style="font-weight:600; font-size:24px; text-align:left">
                检测目标
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019111401.jpg")}" style="width:60%" alt="汽车仪表盘视觉检测" />
            </div>
            <p style="font-weight:600; font-size:24px; text-align:left">
                检测内容
            </p>
            <p>

                <ul class="lic">
                    <li>
                        1、定位切割位置
                    </li> 
                     <li>
                        2、平滑检测
                    </li> 
                </ul>
            </p>
            <p style="font-weight:600; font-size:24px; text-align:left">
                视觉定位
            </p>                   
        
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019111402.jpg")}" style="width:60%" alt="视觉定位" />
            </div>
            <p>
                定位图
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019111403.jpg")}" style="width:60%" alt="视觉定位定位图" />
            </div>

            <p style="font-weight:600; font-size:24px; text-align:left">
                检测过程
            </p>
            <p>

                <ul class="lic">
                    <li>
                        1、在标定物范围内寻找较为黑色的像素点。
                    </li>
                    <li>
                        2、在该像素点集合获取类矩形区域
                    </li>
                    <li>
                        3、切割后寻找白色像素点。如果白色像素点多，认为不平滑。
                    </li>

                </ul>
            </p>



        </div>
        <div class="col-md-2"></div>
    </div>
</div>
</div>
</div>
      `,
          info: "视觉定位切割工业是立国之本、强国之基；信息化是最鲜明的时代特征之一，正在深刻重塑人类社会我国工业化建设取得了重大成就，尤其是汽车工业自动化。整车的制造与装配基本实现无人制造。今天为大家介绍汽车仪表盘的一个小孔切割。在利用机器视觉前，是人工切割。现在人工成本相当高，而且人会带着各种情绪上班。 一不留神就会装口切大，切大了就不美观。切小了，配件无法进入这个孔。",
        },
        {
          imgUrl: require("@/assets/img/news/2019110800.jpg"),
          title: "牙膏管上料外观检测",
          time: "2019-11-08 17:00",
          content: `
        <div>
<div class="div-middle">
<div class="row">
    <div class="col-md-10">
        <div class="context" style="text-align:left; padding:10px;">
            <p style="font-weight:600; font-size:22px; text-align:left">

            </p>
            <p>
                牙膏是我们日常生活中使用度极高的用品之一，一般医生建议-三三三原则。什么是一三三三原则，粗略给大家介绍一下：一日三餐后三分钟内刷三分钟。
            </p>
            <p>
                随着人们对生活品质要求的不断提高，生活用品的品质要求也越来越高，从以前追求价格低廉到现在追求高品质体验。如果您的产品内在品质很好，
                在一定程度上利于产品的长期发展。如果不重视外观的包装，那么就不会受到消费者的青睐。
                就是您要选择一款牙膏，如果产品上的印刷、包装、封印都是错的、不合格的。您还会选择这样的产品来刷你的牙齿吗？答案是否定的。
            </p>
            <p>
                我们今天要介绍的是牙膏管的上料与外观检测。
            </p> 

            <p style="font-weight:600; font-size:24px; text-align:left">
                检测目标
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019110801.jpg")}" style="width:60%" alt="牙膏管视觉检测" />
            </div>
            <p style="font-weight:600; font-size:24px; text-align:left">
                检测内容
            </p>
            <p>

                <ul class="lic">
                    <li>
                        1、印刷检测，模糊度检测。
                    </li>
                    <li>
                        2、关键文字检测，“净重”检测。
                    </li>
                    <li>
                        3、颜色标签检测。
                    </li>
                    <li>
                        4、正反上料检测。
                    </li>

                </ul>
            </p>
            <p style="font-weight:600; font-size:24px; text-align:left">
                印刷检测
            </p>
            <p>
                牙膏说明面，检测部份字体印刷是否清析。
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019110802.jpg")}" style="width:60%" alt="印刷视觉检测" />
            </div>
            <p style="font-weight:600; font-size:24px; text-align:left">
                关键文字检测
            </p>
            <p>
                关键字“净重”是否正常、清析印刷。
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019110803.jpg")}" style="width:60%" alt="关键字视觉检测" />
            </div>


            <p style="font-weight:600; font-size:24px; text-align:left">
                颜色标签检测
            </p>
            <p>
                牙膏底部颜色条检测，常用的有红、蓝、绿及黑色等。绿色表示纯天然；蓝色表示一部分天然，一部分药用成分；红色表示一半是天然成分，一半是化学成分；黑色表示全是化学成分。
                每款产品只能有一种颜色，而且必须符合国家相关规定。颜色的不同会直接影响消费者的选择。
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019110804.jpg")}" style="width:60%" alt="牙膏颜色标签视觉检测" />
            </div>


            <p style="font-weight:600; font-size:24px; text-align:left">
                正反上料检测
            </p>
            <p>
                每款牙膏的外观都应该是一样的。例如我们检测这款。绿色是牙膏管头。紫色是牙膏管尾。那生产牙膏上料时就不能搞错了。

            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019110805.jpg")}" style="width:60%" alt="牙膏正反上料检测" />
            </div>

            <p>
                下图为我们做颜色处理的图片。左边紫色像素点多的一边为牙膏尾。右边绿色像素点多就是牙膏头
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019110806.jpg")}" style="width:60%" alt="牙膏正反上料检测" />
            </div>

        </div>
        <div class="col-md-2"></div>
    </div>
</div>
</div>
</div>
      `,
          info: "牙膏是我们日常生活中使用度极高的用品之一，一般医生建议-三三三原则。什么是一三三三原则，粗略给大家介绍一下：一日三餐后三分钟内刷三分钟。随着人们对生活品质要求的不断提高，生活用品的品质要求也越来越高，从以前追求价格低廉到现在追求高品质体验。如果您的产品内在品质很好， 在一定程度上利于产品的长期发展。如果不重视外观的包装，那么就不会受到消费者的青睐。 就是您要选择一款牙膏，如果产品上的印刷、包装、封印都是错的、不合格的。您还会选择这样的产品来刷你的牙齿吗？答案是否定的。我们今天要介绍的是牙膏管的上料与外观检测。",
        },
        {
          imgUrl: require("@/assets/img/news/2019103000.jpg"),
          title: "圆形LED灯视觉检测装配",
          time: "2019-10-30 14:00",
          content: `
        <div>
<div class="div-middle">
<div class="row">
    <div class="col-md-10">
        <div class="context" style="text-align:left; padding:10px;">
            <p style="font-weight:600; font-size:22px; text-align:left">
                微林软件使命：炼就火眼金睛，成就智能机器！
            </p>
            <p>
                工业自动化设备是指应用于电子行业、新能源、汽车、医疗健康和物流仓储等领域的自动化加工、检测、控制系统及相关仪器设备。
                其制造具有较高的技术含量，通常融合了机械系统、电气控制系统、传感器系统、信息管理系统及网络系统等技术。
                工业自动化设备能有效提高生产制造的效率和可靠性，减少生产过程对人工的依赖。
            </p>
            <p>
                我国在经过多年高速的发展，已基本实现工业机械化，但距离工业自动化还有很大差距。但随着机器人、人工智能的升温，工业自动化趋势愈演愈烈，
                未来发展前景日趋明朗。推动工业自动化发展，
                不仅有助于促进传统行业进行改革，还将提升我国工业信息化程度，发展潜力巨大。
            </p>
            <p>
                全球工业自动化产业在2019年将达到2250亿美元，相比2013年增长30%；由于进入本行业较晚且缺乏核心技术积累，我国的工业自动化行业发展较为缓慢。
            </p>
            <p>
                下一个工业自动化的口尖浪口将是什么？毫无疑问是人工智能AI。今天为大家介绍我们公司机械臂装配LED灯的项目。
            </p>

            <p style="font-weight:600; font-size:24px; text-align:left">
                装配标的
            </p>

            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019103001.jpg")}" style="width:60%" alt="LED灯配件" />
            </div>

            <p style="font-weight:600; font-size:24px; text-align:left">
                装配过程
            </p>
            <p>

                <ul class="lic">
                    <li>
                        1、先检测LED灯芯板正反面。
                    </li>
                    <li>
                        2、检测灯罩方向。
                    </li>
                    <li>
                        3、依据灯罩方向将灯芯板放入到灯罩。
                    </li>

                </ul>
            </p>


            <p style="font-weight:600; font-size:20px; text-align:left">
                灯芯正反面检测
            </p>
            <p style="font-weight:600; font-size:26px; text-align:left">
                灯芯正面
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019103002.jpg")}" style="width:60%" alt="灯芯板正面" />
            </div>
            <p style="font-weight:600; font-size:26px; text-align:left">
                灯芯反面
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019103003.jpg")}" style="width:60%" alt="灯芯板反面" />
            </div>

            <p style="font-weight:600; font-size:24px; text-align:left">
                检测过程
            </p>
            <p>

                <ul class="lic">
                    <li>
                        1、依据上面两张图片分析得出，正面有比较大面积的白像素点。
                    </li>
                    <li>
                        2、那先定位到整个芯片区域。
                    </li>
                    <li>
                        3、为了减少确定区域。在芯片区域再寻找圆。
                    </li>
                    <li>
                        4、在指定的圆区域获取白色像素点。
                    </li>

                </ul>
            </p>

            <p style="font-weight:600; font-size:20px; text-align:left">
                灯罩方向检测
            </p>

            <br />
            <br />
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019103004.jpg")}" style="width:60%" alt="灯罩方向检测" />
            </div>
            <p style="font-weight:600; font-size:24px; text-align:left">
                检测过程
            </p>
            <p>

                <ul class="lic">
                    <li>
                        1、依据灯罩安装特性可以看到有个凸位。
                    </li>
                    <li>
                        2、视觉判断有凸位的为上方。
                    </li>
                    <li>
                        3、依据灯芯的上方与灯罩的上方，将两合在一起。
                    </li>
                    
                </ul>
            </p>
           
            <p style="font-weight:600; font-size:26px; text-align:left">
                两项检测  相关配置信息
            </p>
            <p>
                <ul class="lic">
                    <li>
                        1、度*500W黑白
                    </li>
                    <li>
                        2、12mm镜头
                    </li>
                    <li>
                        3、WD：500mm
                    </li>
                    <li>
                        4、W：215mm
                    </li>
                    <li>
                        5、H：167mm
                    </li>
                    <li>
                        6、曝光时间：414us
                    </li>
                    <li>
                        7、长条形白色光源：亮度 满亮、
                        长条形蓝色光源：亮度 满亮
                    </li>
                   
                </ul>
            </p>


        </div>
        <div class="col-md-2"></div>
    </div>
</div>
</div>
</div>
      `,
          info: "微林软件使命：炼就火眼金睛，成就智能机器！工业自动化设备是指应用于电子行业、新能源、汽车、医疗健康和物流仓储等领域的自动化加工、检测、控制系统及相关仪器设备。 其制造具有较高的技术含量，通常融合了机械系统、电气控制系统、传感器系统、信息管理系统及网络系统等技术。 工业自动化设备能有效提高生产制造的效率和可靠性，减少生产过程对人工的依赖。我国在经过多年高速的发展，已基本实现工业机械化，但距离工业自动化还有很大差距。但随着机器人、人工智能的升温，工业自动化趋势愈演愈烈， 未来发展前景日趋明朗。推动工业自动化发展， 不仅有助于促进传统行业进行改革，还将提升我国工业信息化程度，发展潜力巨大。全球工业自动化产业在2019年将达到2250亿美元，相比2013年增长30%；由于进入本行业较晚且缺乏核心技术积累，我国的工业自动化行业发展较为缓慢。下一个工业自动化的口尖浪口将是什么？毫无疑问是人工智能AI。今天为大家介绍我们公司机械臂装配LED灯的项目。",
        },
        {
          imgUrl: require("@/assets/img/news/2019091800.jpg"),
          title: "两款白色塑料外观检测",
          time: "2019-09-18 11:31",
          content: `
        <div>
<div class="div-middle">
<div class="row">
    <div class="col-md-10">
        <div class="context" style="text-align:left; padding:10px;">

            <p>
                白色塑料，一提起白色塑料大家可能都会想到饮水机、手机壳、充电宝外壳、充电头、电插座等一堆生活当中用到的塑料品。
            </p>
            <p>
                当然，充电头、电插座有污点、变形、毛边我们也可以视而不见。但饮水机呢、饭水机里面的塑料配件呢？
                销微有一点污点，你就会觉得这饮水机是伪劣产品。 现在万能的自媒体一发圈，惨了，**款饮水机质量有问题。
            </p>
            <p>
                所以工业生产白色塑料检测环节特别重要。
            </p>

            <p>
                今天为大家介绍本公司最近接到的白色塑料检测项目。
            </p>
            <p style="font-weight:600; font-size:26px; text-align:center">
                条纹长形白色塑料样品
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091801.jpg")}" style="width:40%" alt="白色塑料外观视觉检测" />
            </div>
            <p>
                如上图所见，塑料件毛边太多，导致安装困难，就算强制安装了密封性也不好。还可能影响整个产品的性能。
                我们系统将毛边标出
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091802.jpg")}" style="width:40%" alt="毛边视觉定位" />
            </div>

            <p style="font-weight:600; font-size:26px; text-align:center">
                凸型白色塑料样品
            </p>
            <p>
                第一项：毛边检测
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091803.jpg")}" style="width:40%" alt="毛边视觉检测" />
            </div>
            <p></p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091803-1.jpg")}" style="width:40%" alt="毛边视觉检测2" />
            </div>
            <p>
                第二项：缺陷检测
            </p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091804.jpg")}" style="width:40%" alt="凹凸视觉检测" />
            </div> <p></p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091804-1.jpg")}" style="width:40%" alt="凹凸视觉检测2" />
            </div>
            <p>
                检测参数：
                <ul class="lic">
                    <li>
                        1、物距90mm
                    </li>
                    <li>
                        2、 视野宽75mm
                    </li>
                    <li>
                        3、光源距离地面100mm
                    </li>
                    <li>
                        4、视野高56mm
                    </li>

                </ul>
            </p>
            <p>拍摄示意图</p>
            <div style="width:100%; margin:0 auto; text-align:center;">
                <img src="${require("@/assets/img/news/2019091805.png")}" style="width:80%" alt="拍摄示意图" />
            </div>
            <p>
                用到的光源：   1、环型白色光源：亮度 满亮
            </p>

            <p>
                用到的相机：度*130W像素+8mm镜头+光圈2.8f
            </p>

        </div>
        <div class="col-md-2"></div>
    </div>
</div>
</div>
</div>
      `,
          info: "白色塑料，一提起白色塑料大家可能都会想到饮水机、手机壳、充电宝外壳、充电头、电插座等一堆生活当中用到的塑料品。当然，充电头、电插座有污点、变形、毛边我们也可以视而不见。但饮水机呢、饭水机里面的塑料配件呢？ 销微有一点污点，你就会觉得这饮水机是伪劣产品。 现在万能的自媒体一发圈，惨了，**款饮水机质量有问题。所以工业生产白色塑料检测环节特别重要。",
        },
      ],
    };
  },
  created() {},
  methods: {
    // 搜索框状态切换
    handleFocus(opc) {
      this.opacity = opc;
    },
    // 跳转详情
    goNewsDetails(url, item) {
      this.$router.push({
        path: url,
        query: {
          title: item.title,
          time: item.time,
          content: item.content,
        },
      });
    },
  },
};
</script>

<style scope>
/* 产品页首图 */
.productClass_banner {
  position: relative;
}
.productClass_bannerImg img {
  width: 100%;
  height: 600px;
  display: flex;
  object-fit: cover;
}
/* 文字内容 */
.productClass_bannerContent {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 600;
  color: #fff;
  width: 814px;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.productClass_bannerContent > div:nth-of-type(1) {
  font-size: 60px;
  text-align: center;
}
.productClass_bannerContent > div:nth-of-type(2) {
  font-size: 28px;
  margin: 40px 0 94px;
  text-transform: uppercase;
  white-space: nowrap;
}
/* 搜索框 */
.productClass_bannerSearch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #fff;
  width: 660px;
  height: 58px;
  box-sizing: border-box;
}
.productClass_bannerSearch input {
  border: none;
  outline: none;
  height: 58px;
  width: 100%;
  padding: 0 20px;
  font-size: 25px;
  box-sizing: border-box;
}
.productClass_bannerSearch img {
  width: 28px;
  height: 28px;
  display: flex;
  object-fit: cover;
  padding: 15px 20px;
  cursor: pointer;
}
/* 列表模块-选择 */
.productClass_list {
  width: 1440px;
  position: relative;
  /* top: -82px; */
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 10px;
}
.productClass_listSelect {
  width: 1200px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 2px 19px 1px rgba(0, 0, 0, 0.15);
}
.productClass_listSelect > div {
  width: 25%;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}
.productClass_listSelect_text {
  margin-top: 24px;
  font-size: 24px;
}
.productClass_listSelect_img img {
  width: 54px;
  height: 54px;
}
/* 被选中状态 */
.productClass_listSelect_status {
  position: absolute;
  bottom: 0;
  width: 25%;
  height: 6px;
  border-radius: 6px;
  background-color: #000080;
  transition: left 0.5s;
}
/* 列表内容 */
.productClass_listContent {
  width: 1440px;
  margin-top: 80px;
  margin-bottom: 120px;
}
.productClass_listContent_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.productClass_listContent_list img {
  width: 100%;
  height: 240px;
  display: flex;
  border-radius: 6px;
  object-fit: cover;
}
.productClass_listContent_list > div {
  margin-bottom: 40px;
  cursor: pointer;
  width: calc(100% / 3);
  padding: 0 40px;
  box-sizing: border-box;
}
.productClass_listContent_list > div > div:nth-of-type(2) {
  font-size: 24px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 分页 */
.productClass_listContent_pagination {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>